import { useState } from 'react';
import ErrorData from 'components/shared/error-data';
import { toast } from 'react-toastify';
import LoadingSpinner from 'components/shared/loading-spinner';
import DeleteModal from 'components/shared/delete-modal';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import CopyTextComponent from 'components/shared/copy-text';
import { Link, useParams } from 'react-router-dom';
import PlansTable from 'components/plans/plans-table';
import EmptyData from 'components/shared/empty-data';
import { StatusLabel } from 'components/members/status-label';
import { StatusLabel as DocumentStatusLabel } from 'components/documents/status-label';
import { StatusLabel as VerificationStatusLabel } from 'components/verification/status-label';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { isEmpty } from 'utils/isEmpty';
import {
  useGetTenantQuery,
  useDeleteTenantMutation,
  useImpersonateTenantMutation,
  useClaimAccountTenantReqMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import Button from 'common/components/button';
import Toggle from 'components/shared/toggle';
import { InfoCard, InfoGrid, InfoField } from 'common/components/info-card';
import { formatPhoneNumber } from 'react-phone-number-input';

function ClaimAccountArea({ userId }) {
  const [claimAccount, { isLoading }] = useClaimAccountTenantReqMutation();
  const [claimUrl, setClaimUrl] = useState('');
  return (
    <>
      <Button
        title='Send Activation Email'
        disabled={isLoading}
        onClick={() => {
          claimAccount({ id: userId })
            .unwrap()
            .then(({ url }) => {
              setClaimUrl(url);
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Send failed');
            });
        }}
        wFull={false}
        className='px-6'
      />
      {claimUrl &&
        <CopyTextComponent
          text={'Copy Activation Link'}
          copyText={claimUrl}
        />
      }
    </>
  );
}

const View = () => {
  const navigate = useNavigate();
  const { memberId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const {
    data: tenantData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
    isFetching: isGetFetching,
  } = useGetTenantQuery(memberId);

  console.log(tenantData)

  const [
    deleteTenant,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useDeleteTenantMutation();

  const [impersonateTenant, { isLoading: isImpersonateLoading }] =
    useImpersonateTenantMutation();

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  if (!tenantData) return <LoadingSpinner />;

  return (
    <DashboardWrapperLayout title={`${tenantData?.firstName} ${tenantData?.lastName}`}>
      {(isUpdateLoading ||
        isUpdateFetching ||
        isGetFetching ||
        isGetLoading) && <LoadingSpinner />}
      {deleteModalOpen && (
        <DeleteModal
          handleDelete={() => {
            deleteTenant({ id: memberId })
              .unwrap()
              .then(() => {
                navigate('/dashboard/members');
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModalOpen(false);
          }}
        />
      )}
      <div className='flex flex-col w-full gap-6'>

        {/* Combined breadcrumb + edit toggle header */}
        <div className='w-full flex gap-3 justify-between'>
          {/* breadcrumb */}
          <div className='flex gap-3 items-center'>
            <Link
              to='/dashboard/home'
              className='font-normal text-base text-[#CED0CE]'
            >
              Dashboard
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            <Link
              to='/dashboard/members'
              className='text-[#CED0CE] text-base font-normal'
            >
              Members
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            <Link 
              to={`/dashboard/members/${tenantData?.id}`} 
              className='text-[#CED0CE] text-base font-normal'
            >
              {tenantData?.firstName} {tenantData?.lastName}
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            <Link 
              to={`/dashboard/members/${tenantData?.id}`} 
              className='text-[#191923] text-base font-normal'
            >
              View
            </Link>
          </div>

          {/* Toggle between view and edit pages */}
          {/* Don't show in new member view (leads to view error) */}
          {tenantData?.id &&
            <Toggle 
              selected={'view'}
              editPath={`/dashboard/members/${tenantData?.id}/edit`}
              viewPath={`/dashboard/members/${tenantData?.id}`}
            />
          }
        </div>

        {/* Action buttons */}
        <div className='w-full flex gap-2 justify-end items-center'>
          {/* Show account activation button conditionally based on the email confirmation status */}
          {!tenantData?.emailConfirmed && <ClaimAccountArea userId={tenantData?.id} />}
          <Button
            onClick={() => {
              navigate(`/dashboard/plans/new?userID=${tenantData.id}`);
            }}
            title='Add Plan'
            wFull={false}
            className='px-6'
          />
          <Button
            disabled={isImpersonateLoading}
            onClick={() => {
              impersonateTenant({ id: tenantData.id })
                .unwrap()
                .then(({ token }) => {
                  let url = `${process.env.REACT_APP_IMPERSONATION_URL}?token=${token}`;
                  window.open(url, '_blank');
                });
              /* location.href = */
              /* navigate(`/dashboard/plans/new?userID=${tenantData.id}`); */
            }}
            theme='secondary'
            title='Log In As User'
            wFull={false}
            className='px-6'
          />
          <Button
            onClick={() => {
              setDeleteModalOpen(true);
            }}
            theme='secondary'
            title='Deactivate User'
            wFull={false}
            className='px-6'
          />
        </div>

        {/* Member Details Table */}
        <InfoCard title='Member Information'>
          <InfoGrid>
            <InfoField title='Name'>
              {tenantData?.firstName} {tenantData?.lastName}
            </InfoField>
            <InfoField title='Phone'>
              {formatPhoneNumber(tenantData?.phone)}
            </InfoField>
            <InfoField title='Email' className='col-span-2'>
              {tenantData?.email}
            </InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Member Status'>
              <StatusLabel status={tenantData.status} size='base' />
            </InfoField>
            <InfoField title='ID Verification'>
              <DocumentStatusLabel status={tenantData?.idVerification} size='base' />
            </InfoField>
            <InfoField title='Email Verification'>
              <VerificationStatusLabel status={tenantData?.emailConfirmed} size='base' />
            </InfoField>
            <InfoField title='Phone Verification'>
              <VerificationStatusLabel status={tenantData?.phoneConfirmed} size='base' />
            </InfoField>
          </InfoGrid>
        </InfoCard>

        {/* Member's Plan Table */}
        <InfoCard title='Member Plans'>
          {/* Member Plan table */}
          {isEmpty(tenantData?.plans) ? (
            <EmptyData />
          ) : (
            <PlansTable
              showMember={false}
              isRounded={false}
              plans={tenantData?.plans}
            />
          )}
        </InfoCard>
      </div>
    </DashboardWrapperLayout>
  );
};

export default View;
