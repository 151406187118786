import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  FaBuildingColumns,
  FaBuildingShield,
  FaPeopleLine,
  FaRightFromBracket,
} from 'react-icons/fa6';
import { FiLogOut } from 'react-icons/fi';
import { IoIosSend } from 'react-icons/io';
import { PiWarningCircleFill } from 'react-icons/pi';
import { RiComputerLine } from 'react-icons/ri';
import ActiveLink from '../../components/shared/active-link';
import LogoImage from 'common/components/logo-image';
import { useNavigate } from 'react-router-dom';
import { logout, selectUser } from '../../redux/features/auth/authSlice';
import { extractInitials } from 'common/utils/extractInitials';
import { Icon } from '@chakra-ui/react';

const DashboardWrapperLayout = ({ children, title = 'Title' }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  return (
    <main className='h-screen flex'>
      {/* aside */}
      <aside className='h-full w-full max-w-xs flex flex-col relative gap-8 border-r border-gray-200 px-5 py-8'>
        {/* icon */}
        <div className='flex flex-col gap-2'>
          <LogoImage className='ml-6' small={true} />
          <span className='ml-6 text-sm uppercase font-semibold tracking-wider'>
            Admin Portal
          </span>
        </div>

        {/* link buttons */}
        <div className='flex flex-col gap-3'>
          <ActiveLink
            Icon={RiComputerLine}
            to={'/dashboard/home'}
            text='Dashboard'
          />
          <ActiveLink
            Icon={PiWarningCircleFill}
            to={'/dashboard/claims'}
            text='Claims'
          />
          <ActiveLink Icon={IoIosSend} to={'/dashboard/plans'} text='Plans' />
          <ActiveLink
            Icon={FaPeopleLine}
            to={'/dashboard/members'}
            text='Members'
          />
          <ActiveLink
            Icon={FaBuildingShield}
            to={'/dashboard/properties'}
            text='Properties'
          />
          <ActiveLink
            Icon={FaBuildingColumns}
            to={'/dashboard/owners'}
            text='Companies'
          />
        </div>

        {/* profile details and logout */}
        <div className='flex flex-col w-full mt-auto gap-3'>
          {/* profile details */}
          <div className='cursor-pointer hover:bg-gray-100 border-y border-[#E5E7EB] text-[#191923] text-base font-medium py-4 pl-3 grid grid-cols-[40px_auto] gap-3'>
            {/* profile image */}
            <span className='inline-flex bg-black h-10 w-10 rounded-[50%] items-center justify-center text-white font-bold text-sm uppercase'>
              {extractInitials(user?.name || 'John Doe')}
            </span>

            {/* name and email address */}
            <div className='flex flex-col w-full gap-1 overflow-hidden'>
              <span className='text-base font-medium text-[#191923] truncate ...'>
                {user?.name || 'User'}
              </span>
              <span className='text-[#CED0CE] font-normal text-sm truncate ...'>
                {user?.email}
              </span>
            </div>
          </div>

          {/* Logout */}
          <div
            onClick={() => {
              dispatch(logout());
              navigate('/login');
            }}
            className='flex items-center gap-4 transition-all rounded-[8px] px-6 py-4 hover:no-underline hover:bg-[#bbbbbb4e] cursor-pointer text-[#6A6C70]'
          >
            <Icon as={FaRightFromBracket} className='text-2xl' />
            <span className='text-base font-medium'>Logout</span>
          </div>
        </div>
      </aside>

      {/* main section */}
      <section className='w-full relative px-8 py-8 flex flex-col overflow-auto gap-8 mobile:px-6 mobile:gap-5 mobile:relative mobile:z-[100]'>
        {/* heading */}
        <div className='pb-5 flex items-center w-full border-b border-[#E5E7EB] gap-4'>
          <p className='text-4xl text-[#191923] font-bold mobile:text-3xl'>
            {title}
          </p>
        </div>

        {/* Content */}
        {children}
      </section>
    </main>
  );
};

export default DashboardWrapperLayout;
