import { useState } from 'react';
import { Link } from 'react-router-dom';
import ErrorData from 'components/shared/error-data';
import ClaimsTable from 'components/claims/claims-table';
import EmptyData from 'components/shared/empty-data';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import TablePaginateWrapper from 'layouts/table-paginate-wrapper';
import LoadingSpinner from 'components/shared/loading-spinner';
import { isEmpty } from 'utils/isEmpty';
import { useFindClaimsQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';

const View = ({ statuses }) => {
  // DATA INITIALIZATION
  const pageSize = 25;
  const [sortVal, setSort] = useState('-submissionAt');

  // STATES
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: claimsQuery,
    isError: isGetError,
    error: getError,
    isLoading: findClaimsLoading,
  } = useFindClaimsQuery({
    limit: pageSize,
    page: currentPage,
    sort: sortVal,
    status: statuses,
  });

  const { data: claimsData, meta: claimsMeta } = claimsQuery || {
    data: [],
    meta: { count: 0 },
  };

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }
  console.log('All claims?', claimsData);

  return (
    <DashboardWrapperLayout title='Claims'>
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Dashboard
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link to='' className='text-[#191923] text-base font-normal'>
            Claims{' '}
          </Link>
        </div>

        {/* table */}
        {findClaimsLoading && <LoadingSpinner center={true} />}
        {isEmpty(claimsData) ? (
          <EmptyData />
        ) : (
          <TablePaginateWrapper
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalCount={claimsMeta.count}
          >
            <ClaimsTable claims={claimsData} setSort={setSort}
              sortVal={sortVal}/>
          </TablePaginateWrapper>
        )}
      </div>
    </DashboardWrapperLayout>
  );
};

export default View;
