import { useState } from 'react';
import ErrorData from 'components/shared/error-data';
import { toast } from 'react-toastify';
import LoadingSpinner from 'components/shared/loading-spinner';
import DeleteModal from 'components/shared/delete-modal';
import { Link, useParams } from 'react-router-dom';
import PlansTable from 'components/plans/plans-table';
import Button from 'common/components/button';
import EmptyData from 'components/shared/empty-data';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { isEmpty } from 'utils/isEmpty';
import {
  useGetPropertyQuery,
  useDeletePropertyMutation,
  useFindPlansQuery,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import Toggle from 'components/shared/toggle';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import RentalType from 'components/properties/rental-type';
import { StatusLabel } from 'components/properties/status-label';
import { formatPhoneNumber } from 'react-phone-number-input';

const View = () => {
  const navigate = useNavigate();
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {},
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  };
  const { propertyId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const {
    data: propertyData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
    isFetching: isGetFetching,
  } = useGetPropertyQuery(propertyId);
  const [
    deleteProperty,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
    // { isLoading: updatePlanLoading },
  ] = useDeletePropertyMutation();

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  let plansData = propertyData?.plans || [];

  if (!propertyData) return <LoadingSpinner />;
  return (
    <DashboardWrapperLayout title={propertyData?.name || 'Property Details'}>
      {/* Loading Animation */}
      {(isUpdateLoading ||
        isUpdateFetching ||
        isGetFetching ||
        isGetLoading) && <LoadingSpinner />}

      {/* Un-used / not-yet-implemented delete function */}
      {deleteModalOpen && (
        <DeleteModal
          handleDelete={() => {
            deleteProperty({ id: propertyId })
              .unwrap()
              .then(() => {
                navigate('/dashboard/properties');
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModalOpen(false);
          }}
        />
      )}

      <div className='flex flex-col w-full gap-6'>
        {/* Combined breadcrumb + edit toggle header */}
        <div className='w-full flex gap-3 justify-between'>
          {/* breadcrumb */}
          <div className='flex gap-3 items-center'>
            <Link
              to='/dashboard/home'
              className='font-normal text-base text-[#CED0CE]'
            >
              Dashboard
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            <Link
              to='/dashboard/properties'
              className='text-[#CED0CE] text-base font-normal'
            >
              Properties{' '}
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            <Link
              to={`/dashboard/properties/${propertyData?.id}`}
              className='text-[#CED0CE] text-base font-normal'
            >
              {propertyData?.id.toUpperCase()}
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            <Link
              to={`/dashboard/properties/${propertyData?.id}`}
              className='text-[#191923] text-base font-normal'
            >
              View{' '}
            </Link>
          </div>

          <Toggle
            selected={'view'}
            editPath={`/dashboard/properties/${propertyData?.id}/edit`}
            viewPath={`/dashboard/properties/${propertyData?.id}`}
          />
        </div>

        {/* Action buttons */}
        <div className='w-full flex gap-2 justify-end items-center'>
          <Button
            onClick={() => {
              navigate(`/dashboard/plans/new?property=${propertyData?.id}`);
            }}
            title='Add Plan'
            wFull={false}
            className='px-6'
          />
          {propertyData?.owner && (
            <Button
              onClick={() => {
                navigate(`/dashboard/owners/${propertyData.owner}`);
              }}
              title='Company Details'
              wFull={false}
              className='px-6'
              theme='secondary'
            />
          )}
          {/* Delete button commented out for now */}
          {/* <Button
            onClick={() => {
              setDeleteModalOpen(true);
            }}
            Icon={AiOutlineDelete}
            theme='secondary'
            title='Delete Property'
            wFull={false}
            className='px-6'
          /> */}
        </div>

        {/* Property Details Table */}
        <InfoCard title='Property Information'>
          <InfoGrid>
            <InfoField title='Name'>{propertyData?.name}</InfoField>
            <InfoField title='Status'>
              <StatusLabel status={propertyData?.status} />
            </InfoField>
            <InfoField title='ID' className='col-span-2'>
              <Link
                onClick={(e) => {
                  copyToClipboard(propertyData?.id);
                }}
              >
                {propertyData?.id.toUpperCase()}
              </Link>
            </InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Contact Email'>{propertyData?.email}</InfoField>
            <InfoField title='Contact Phone'>
              {formatPhoneNumber(propertyData?.phone)}
            </InfoField>
            <InfoField title='Website'>{propertyData?.website}</InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Default Rate'>
              {`${(propertyData?.defaultRate * 100).toFixed(2)}%`}
            </InfoField>
            <InfoField title='Risk Score'>
              {`${propertyData?.riskScore || '- '} Risk Score`}
            </InfoField>
            <InfoField title='Property Type'>
              <RentalType rentalType={propertyData?.kind} />
            </InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Street Address 1' className='col-span-2'>
              {propertyData?.address.streetAddress1}
            </InfoField>
            {propertyData?.address.streetAddress2 && (
              <InfoField title='Street Address 2' className='col-span-2'>
                {propertyData?.address.streetAddress2}
              </InfoField>
            )}
            <InfoField title='City'>{propertyData?.address.city}</InfoField>
            {propertyData?.address.state && (
              <InfoField title='State'>{propertyData?.address.state}</InfoField>
            )}
            <InfoField title='Postal Code'>
              {propertyData?.address.postalCode}
            </InfoField>
            <InfoField title='Country'>
              {propertyData?.address.country}
            </InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Notes' className='col-span-4'>
              {propertyData?.notes}
            </InfoField>
          </InfoGrid>
        </InfoCard>

        {/* Management and Owner Information */}
        {propertyData?.owner && (
          <InfoCard title='Management Information'>
            <InfoGrid>
              <InfoField title='Company ID'>{propertyData?.owner}</InfoField>
            </InfoGrid>
          </InfoCard>
        )}

        {/* Plans at this property */}
        {/* Won't work until we implement plans */}
        <InfoCard title='Property Plans'>
          {/* Member Plan table */}
          {isEmpty(plansData) ? (
            <EmptyData />
          ) : (
            <PlansTable showMember={true} isRounded={false} plans={plansData} />
          )}
        </InfoCard>
      </div>
    </DashboardWrapperLayout>
  );
};

export default View;
