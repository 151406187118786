import React, { useState, useRef } from 'react';
import Toggle from 'components/shared/toggle';
import { useSearchParams } from 'react-router-dom';
import StatusLabel from 'components/plans/status-label';
import AutoCompleteAddress from 'components/shared/autoaddress';
import * as Yup from 'yup';
import moment from 'moment';
import { isEmpty } from 'utils/isEmpty';
import { useDispatch } from 'react-redux';
// import FormError from "@cios/common/dist/components/shared/form-error";
// import { FormError } from "@cios/common";
import FormError from 'components/shared/form-error';
import DocumentPreview from 'components/shared/document-preview';
import ErrorData from 'components/shared/error-data';
import { toast } from 'react-toastify';
import { Form, Formik, useFormikContext } from 'formik';
import { RiEdit2Fill } from 'react-icons/ri';
import { Link, useParams, useNavigate } from 'react-router-dom';
import PaymentPlanCard from 'components/plans/payment-plan-card';
import CheckBox from 'components/shared/checkbox';
import Input from 'components/shared/input';
import SelectInput from 'components/shared/select-input';
import AccordionWrapperLayout from 'layouts/accordion-wrapper';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { ACTIVE_PLAN, REQUESTED_PLAN, documentStatusEnum } from 'utils/enums';
import { toDDMMYYYY, toDatePickerFormat } from 'utils/date';
import { uploadFileToS3 } from 'utils/uploadS3';
import {
  useFindOwnersQuery,
  useClaimAccountTenantReqMutation,
  useGetPlanQuery,
  useUpdatePlanMutation,
  useUploadRequestUrlMutation,
  useCreatePlanMutation,
  useImpersonateTenantMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { planStatusEnum } from 'utils/enums';
import LoadingSpinner from 'components/shared/loading-spinner';
import filterLabelValues from 'utils/filterLabelValues';
import FileUploadInput from 'components/shared/file-upload-input';
import {
  documentStatusOptions,
  planCurrencyOptions,
  paymentPlanOptions,
} from 'utils/selectOptions';
import Button from 'common/components/button';
import { InfoCard, InfoGrid, InfoField } from 'common/components/info-card';

const ONE_TIME_PAYMENT_DISCOUNT_RATE = 0.083;

// monthlyRentPercent
const MONTHLY_RENT_PERCENTAGE_FOR_MONTHLY_PRICE = 0.0389;

function getCalcs({
  startDate,
  endDate,
  monthlyRent,
  monthlyRentPercentageRate,
  oneTimeDiscountedRate,
}) {
  let start = moment(startDate);
  let end = moment(endDate);
  const term = end.diff(start, 'months');

  const total = monthlyRent * term * monthlyRentPercentageRate;
  const oneTimeDiscount = total * oneTimeDiscountedRate;
  let oneTimePayment = (total - oneTimeDiscount).toFixed(2);
  let monthlyPayment = (total / term).toFixed(2);
  let oneTimeMonthlyAmt = (oneTimePayment / term).toFixed(2);
  return {
    term,
    oneTimePayment,
    monthlyPayment,
    oneTimeMonthlyAmt,
  };
}

function getLastStatusMessage(plan) {
  if (plan.status === planStatusEnum.rejected && plan.rejectedLeases) {
    let len = plan.rejectedLeases.length;
    return plan.rejectedLeases[len - 1].reason;
  } else {
    return '';
  }
}

const allPlanStatusOptions = [
  { label: 'Active', value: planStatusEnum.active },
  { label: 'Pending', value: planStatusEnum.pending },
  { label: 'Complete', value: planStatusEnum.complete },
  { label: 'Rejected', value: planStatusEnum.rejected },
  { label: 'Cancelled', value: planStatusEnum.cancelled },
  { label: 'Created', value: planStatusEnum.created },
  { label: 'Review', value: planStatusEnum.review },
];

const PlanDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { planId } = useParams();

  const {
    data: ownersQuery,
    isError: isGetOwnerError,
    error: getOwnerError,
    isLoading: findOwnersLoading,
  } = useFindOwnersQuery({
    limit: 100,
    page: 1,
    status: 'Active',
    sort: '-name',
  });

  const [
    updatePlan,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useUpdatePlanMutation();

  const [impersonateTenant, { isLoading: isImpersonateLoading }] =
    useImpersonateTenantMutation();

  const [
    createPlan,
    { isLoading: isCreateLoading, isFetching: isCreateFetching },
  ] = useCreatePlanMutation();

  const queryParams = new window.URLSearchParams(window.location.search);
  const userId = queryParams.get('userId');
  const propertyId = queryParams.get('property');

  console.log('User id?', userId);
  console.log('Property id?', propertyId);

  const [uploadStatus, setUploadStatus] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  const {
    data: planQuery,
    isError: isGetError,
    error: getError,
  } = useGetPlanQuery(planId, { skip: userId || !planId });

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  // TODO Have loading screen, or something better if no data available
  if (!planQuery && planId) {
    return <LoadingSpinner />;
  }

  if (!ownersQuery) {
    return <LoadingSpinner />;
  }

  const { data: planData } = planQuery || {
    data: {},
  };

  console.log(planData);

  const {
    streetAddress1,
    streetAddress2,
    city,
    state,
    country,
    postalCode,
    neighborhood,
  } = planData?.lease?.address || {
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    neighborhood: '',
    postalCode: '',
    state: '',
    country: '',
  };

  const dateOfBirthFormatted = planData?.dateOfBirth
    ? toDDMMYYYY(new Date(planData.dateOfBirth))
    : '';

  const visaExpirationFormatted = planData?.visaExpiration
    ? toDDMMYYYY(new Date(planData.visaExpiration))
    : '';

  const startLeaseFormatted = planData?.lease?.startDate
    ? toDDMMYYYY(new Date(planData.lease?.startDate))
    : '';
  const endLeaseFormatted = planData?.lease?.endDate
    ? toDDMMYYYY(new Date(planData.lease?.endDate))
    : '';

  const planType =
    planData.status === planStatusEnum.active ? ACTIVE_PLAN : REQUESTED_PLAN;

  const { name: managerName, email: managerEmail } = planData?.managerId || {};
  let filterValues = [
    planStatusEnum.created,
    planStatusEnum.cancelled,
    planStatusEnum.review,
    planStatusEnum.pending,
    planStatusEnum.rejected,
    planStatusEnum.active,
  ];

  const planStatusOptions = filterLabelValues(
    allPlanStatusOptions,
    filterValues
  );

  const lastStatusMessage = getLastStatusMessage(planData);

  const isInSchool =
    planData?.currentSchool?.name &&
    planData?.currentSchool?.enrollmentDate &&
    planData?.currentSchool?.graduationDate;

  const user = planData?.user;
  const userDocs = user?.documents;
  const docLength = userDocs?.length;
  let idDocumentUrl = '';

  if (docLength) {
    idDocumentUrl = userDocs[docLength - 1]?.url;
  }

  const ownerOptions = (ownersQuery?.data || []).map((o) => {
    return { value: o.id, label: o.name };
  });

  return (
    <DashboardWrapperLayout
      title={planData?.id ? `Plan ${planData?.id.toUpperCase()}` : `New Plan`}
    >
      {(isUpdateLoading || isUpdateFetching) && (
        <LoadingSpinner center={true} />
      )}
      {/* Header infomration */}
      <div className='flex flex-col w-full gap-6'>
        {/* Combined breadcrumb + edit toggle header */}
        <div className='w-full flex gap-3 justify-between'>
          {/* breadcrumb */}
          <div className='flex gap-3 items-center'>
            <Link
              to='/dashboard/home'
              className='font-normal text-base text-[#CED0CE]'
            >
              Dashboard
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            <Link
              to='/dashboard/plans'
              className='text-[#CED0CE] text-base font-normal'
            >
              Plans
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            {planData?.id ? (
              <>
                <Link
                  to={`/dashboard/plans/${planData?.id}`}
                  className='text-[#CED0CE] text-base font-normal'
                >
                  {planData?.id.toUpperCase()}
                </Link>
                <span className='font-normal text-base text-[#CED0CE]'>
                  {'>'}
                </span>
                <Link
                  to={`/dashboard/plans/${planData?.id}/edit`}
                  className='text-[#191923] text-base font-normal'
                >
                  Edit
                </Link>
              </>
            ) : (
              <>
                <Link
                  className='text-[#191923] text-base font-normal'
                >
                  New
                </Link>
              </>
            )}
          </div>

          {/* Don't show toggle if the plan doesn't exist yet */}
          {planData?.id && (
            <Toggle
              selected={'edit'}
              editPath={`/dashboard/plans/${planData?.id}/edit`}
              viewPath={`/dashboard/plans/${planData?.id}`}
            />
          )}
        </div>

        <Formik
          onSubmit={(value) => {
            let valueCp = JSON.parse(JSON.stringify(value));
            if (!valueCp.visaExpiration) {
              delete valueCp.visaExpiration;
            }

            if (!valueCp.visaExpiration) {
              delete valueCp.visaExpiration;
            }

            if (!value.property) {
              valueCp.property = null;
            }

            if (!value.user) {
              valueCp.user = null;
            }

            if (planId) {
              updatePlan({
                id: planData.id,
                ...valueCp,
              })
                .unwrap()
                .then((data) => {
                  navigate(`/dashboard/plans/${data?.id}`);
                })
                .catch((e) => {
                  toast.error(e?.data?.msg || 'Update Failed');
                });
            } else {
              createPlan({
                user: userId,
                property: propertyId,
                ...valueCp,
              })
                .unwrap()
                .then((data) => {
                  navigate(`/dashboard/plans/${data.id}`);
                })
                .catch((e) => {
                  toast.error(e?.data?.msg || 'Create Failed');
                });
            }
          }}
          initialValues={{
            status: planData?.status,
            /* dateOfBirth: toDatePickerFormat(planData?.dateOfBirth), */
            /* relationshipStatus: planData?.relationshipStatus || '', */
            /* residencyStatus: planData?.residencyStatus || '', */
            /* visaExpiration: toDatePickerFormat(planData?.visaExpiration), */
            /* addressesLived: planData?.addressesLived || 0, */
            /* terminatedLeases: planData?.terminatedLeases || 0, */
            /* isActiveMilitary: planData?.isActiveMilitary || false, */
            /* prevSchool: { */
            /*   name: planData?.prevSchool?.name || '', */
            /*   level: planData?.prevSchool?.level || '', */
            /*   graduationDate: toDatePickerFormat( */
            /*     planData?.prevSchool?.graduationDate */
            /*   ), */
            /*   enrollmentDate: toDatePickerFormat( */
            /*     planData?.prevSchool?.enrollmentDate */
            /*   ), */
            /*   degreeName: planData?.prevSchool?.degreeName || '', */
            /* }, */
            /* currentSchool: { */
            /*   name: planData?.currentSchool?.name || '', */
            /*   level: planData?.currentSchool?.level || '', */
            /*   graduationDate: toDatePickerFormat( */
            /*     planData?.currentSchool?.graduationDate */
            /*   ), */
            /*   enrollmentDate: toDatePickerFormat( */
            /*     planData?.currentSchool?.enrollmentDate */
            /*   ), */
            /*   degreeName: planData?.currentSchool?.degreeName || '', */
            /* }, */
            /* jobTurnover: planData?.jobTurnover || 0, */
            /* jobRelocations: planData?.jobRelocations || 0, */
            /* fraudConviction: planData?.fraudConviction || false, */
            /* felonyConviction: planData?.felonyConviction || false, */
            /* movePlans: planData?.movePlans || false, */
            /* jobQuitPlans: planData?.jobQuitPlans || false, */
            owner: planData?.owner || null,
            property: propertyId || planData?.property?.id || planData?.property || '',
            user: planData?.user?.id || planData?.user || '',
            leaseVerification: planData?.leaseVerification,
            idVerification: planData?.idVerification,
            step: planData?.step || 1,
            lease: {
              address: {
                streetAddress1,
                streetAddress2,
                city,
                postalCode,
                neighborhood,
                state,
                country,
              },
              currency: planData?.lease?.currency || null,
              landlordName: planData?.lease?.landlordName,
              monthlyRent: planData?.lease?.monthlyRent,
              document: planData?.lease?.document,
              startDate: toDatePickerFormat(planData?.lease?.startDate),
              endDate: toDatePickerFormat(planData?.lease?.endDate),
            },
            payment: {
              monthlyRentPercentageRate:
                planData?.payment?.monthlyRentPercentageRate ||
                MONTHLY_RENT_PERCENTAGE_FOR_MONTHLY_PRICE,
              monthlyRentPercentageRate:
                planData?.payment?.monthlyRentPercentageRate ||
                MONTHLY_RENT_PERCENTAGE_FOR_MONTHLY_PRICE,
              oneTimeDiscountedRate:
                planData?.payment?.oneTimeDiscountedRate ||
                ONE_TIME_PAYMENT_DISCOUNT_RATE,
              oneTimePayment: planData?.payment?.oneTimePayment || 0,
              monthlyPayment: planData?.payment?.monthlyPayment || 0,
              choice: planData?.payment?.choice,
            },
          }}
          validationSchema={Yup.object().shape({
            status: Yup.string().optional(),
            rejectedMessage: Yup.string().optional(),
            property: Yup.string().test(
              'is-empty-or-24-chars',
              'Must be an empty string or exactly 24 characters long',
              (value) =>
                value === '' ||
                value === null ||
                value === undefined ||
                (value && value.length === 24)
            ),
            user: Yup.string().test(
              'is-empty-or-24-chars',
              'Must be an empty string or exactly 24 characters long',
              (value) =>
                value === '' ||
                value === null ||
                value === undefined ||
                (value && value.length === 24)
            ),
            lease: Yup.object()
              .shape({
                monthlyPayment: Yup.number()
                  .min(0, 'The value must be greater than 0')
                  .test(
                    'check-empty-monthlyPayment',
                    'Monthly Payment cannot be empty',
                    function (value) {
                      // Check if the initial value was set and if so, require a non-empty current value
                      const initialValue = planData?.lease?.monthlyPayment;
                      if (initialValue && initialValue > 0 && !value) {
                        return false;
                      }
                      return true;
                    }
                  )
                  .optional(),
                oneTimePayment: Yup.number()
                  .min(0, 'The value must be greater than 0')
                  .test(
                    'check-empty-onetimePayment',
                    'One-Time Payment cannot be empty',
                    function (value) {
                      // Check if the initial value was set and if so, require a non-empty current value
                      const initialValue = planData?.lease?.oneTimePayment;
                      if (initialValue && initialValue > 0 && !value) {
                        return false;
                      }
                      return true;
                    }
                  )
                  .optional(),
              })
              .optional(),
          })}
        >
          {({
            handleSubmit,
            handleBlur,
            handleChange,
            isValid,
            dirty: isDirty,
            values,
            setFieldValue,
          }) => {
            const {
              term: leaseTerm,
              oneTimePayment,
              monthlyPayment,
              oneTimeMonthlyAmt,
            } = getCalcs({
              startDate: values?.lease.startDate,
              endDate: values?.lease.endDate,
              monthlyRent: values.lease.monthlyRent,
              monthlyRentPercentageRate:
                values.payment.monthlyRentPercentageRate,
              oneTimeDiscountedRate: values.payment.oneTimeDiscountedRate,
            });
            return (
              <Form className='flex flex-col w-full gap-6'>
                {/* Action Buttons */}
                <div className='w-full flex gap-2 justify-end items-center'>
                  <Button
                    disabled={!isValid || !isDirty}
                    onClick={handleSubmit}
                    title='Save Changes'
                    wFull={false}
                    className='px-6'
                  />
                  {values.owner ? (
                    <Button
                      theme='secondary'
                      type='button'
                      title='Remove Building Owner'
                      onClick={() => {
                        setFieldValue('owner', null);
                      }}
                      wFull={false}
                      className='px-6'
                    />
                  ) : (
                    <span />
                  )}
                </div>

                <InfoCard title='Member Information'>
                  <InfoGrid>
                    <InfoField title='Member ID'>
                      <Input
                        placeholderText='Member ID'
                        value={values.user}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='user'
                      />
                      <FormError name='user' />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>

                <InfoCard title='Plan Information'>
                  <InfoGrid>
                    <InfoField title='Plan Status'>
                      <SelectInput
                        options={planStatusOptions}
                        placeholder='Select Status'
                        name='values.status'
                        value={planStatusOptions.find(
                          (status) => status.value === values.status
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('status', selectedOption?.value || '');
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                    <InfoField title='Application Progress'>
                      <Input
                        type='number'
                        min={1}
                        max={5}
                        placeholderText='Enter Number'
                        value={values.step}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='step'
                        prefix='Step'
                        suffix='/ 5'
                      />
                      <FormError name='step' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Payment Plan'>
                      <SelectInput
                        options={paymentPlanOptions}
                        placeholder='Select Plan'
                        name='payment.choice'
                        value={paymentPlanOptions.find(
                          (choice) => choice.value === values.payment.choice
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'payment.choice',
                            selectedOption?.value || ''
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                    <InfoField title='Base Rate'>
                      <Input
                        type='number'
                        placeholderText='Enter Number'
                        value={values.payment.monthlyRentPercentageRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='payment.monthlyRentPercentageRate'
                      />
                      <FormError name='payment.monthlyRentPercentageRate' />
                    </InfoField>
                    <InfoField title='One-Time Rate'>
                      <Input
                        disabled={true}
                        value={
                          (1 - values.payment.oneTimeDiscountedRate) *
                          values.payment.monthlyRentPercentageRate
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                    <InfoField title='Discount Rate'>
                      <Input
                        type='number'
                        placeholderText='Enter Number'
                        value={values.payment.oneTimeDiscountedRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='payment.oneTimeDiscountedRate'
                      />
                      <FormError name='payment.oneTimeDiscountedRate' />
                    </InfoField>
                    <InfoField title='Plan Term'>
                      <Input
                        disabled={true}
                        value={leaseTerm}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        suffix='months'
                      />
                    </InfoField>
                    <InfoField title='One-Time Total Amount'>
                      <Input
                        disabled={true}
                        value={oneTimePayment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        prefix='$'
                        suffix={values.lease.currency}
                      />
                    </InfoField>
                    <InfoField title='One-Time Monthly Amount'>
                      <Input
                        disabled={true}
                        value={oneTimeMonthlyAmt}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        prefix='$'
                        suffix={values.lease.currency}
                      />
                    </InfoField>
                    <InfoField title='Monthly Recurring Amount'>
                      <Input
                        disabled={true}
                        value={monthlyPayment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        prefix='$'
                        suffix={values.lease.currency}
                      />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>

                <InfoCard title='Lease Information'>
                  <InfoGrid>
                    <InfoField title='Landlord Name'>
                      <Input
                        placeholderText='Name'
                        value={values.lease.landlordName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.landlordName'
                      />
                      <FormError name='lease.landlordName' />
                    </InfoField>
                    <InfoField title='Property ID'>
                      <Input
                        placeholderText='Property ID'
                        value={values.property}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='property'
                      />
                      <FormError name='property' />
                    </InfoField>
                    {/* <InfoField title='Property Owner'>
                      <SelectInput
                        options={ownerOptions}
                        placeholder='Select Building Owner'
                        name='values.owner'
                        value={
                          ownerOptions.find(
                            (status) => status.value === values.owner
                          ) || null
                        }
                        onChange={(selectedOption) => {
                          setFieldValue('owner', selectedOption?.value || null);
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField> */}
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Autofill Address' className='col-span-4'>
                      <AutoCompleteAddress
                        onAddressChange={(newAddress) => {
                          setFieldValue(
                            'lease.address.streetAddress1',
                            newAddress.streetAddress1 || ''
                          );
                          setFieldValue(
                            'lease.address.streetAddress2',
                            newAddress.streetAddress2 || ''
                          );
                          setFieldValue(
                            'lease.address.city',
                            newAddress.city || ''
                          );
                          setFieldValue(
                            'lease.address.state',
                            newAddress.state || ''
                          );
                          setFieldValue(
                            'lease.address.country',
                            newAddress.country || ''
                          );
                          setFieldValue(
                            'lease.address.postalCode',
                            newAddress.zipCode || ''
                          );
                        }}
                      />
                    </InfoField>
                    <InfoField title='Street Address 1' className='col-span-2'>
                      <Input
                        placeholderText='Street Address 1'
                        value={values.lease.address.streetAddress1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.address.streetAddress1'
                      />
                      <FormError name='lease.address.streetAddress1' />
                    </InfoField>
                    <InfoField title='Street Address 2' className='col-span-2'>
                      <Input
                        placeholderText='Street Address 2'
                        value={values.lease.address.streetAddress2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.address.streetAddress2'
                      />
                      <FormError name='lease.address.streetAddress2' />
                    </InfoField>
                    <InfoField title='City'>
                      <Input
                        placeholderText='City'
                        value={values.lease.address.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.address.city'
                      />
                      <FormError name='lease.address.city' />
                    </InfoField>
                    <InfoField title='State'>
                      <Input
                        placeholderText='State'
                        value={values.lease.address.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.address.state'
                      />
                      <FormError name='lease.address.state' />
                    </InfoField>
                    <InfoField title='Postal Code'>
                      <Input
                        placeholderText='Postal Code'
                        value={values.lease.address.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.address.postalCode'
                      />
                      <FormError name='lease.address.postalCode' />
                    </InfoField>
                    <InfoField title='Country'>
                      <Input
                        placeholderText='Country'
                        value={values.lease.address.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.address.country'
                      />
                      <FormError name='lease.address.country' />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Start Date'>
                      <Input
                        type='date'
                        value={values.lease.startDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.startDate'
                      />
                      <FormError name='lease.startDate' />
                    </InfoField>
                    <InfoField title='End Date'>
                      <Input
                        type='date'
                        value={values.lease.endDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.endDate'
                      />
                      <FormError name='lease.endDate' />
                    </InfoField>
                    <InfoField title='Monthly Rent'>
                      <Input
                        type='number'
                        placeholderText='Enter Number'
                        value={values.lease.monthlyRent}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='lease.monthlyRent'
                        prefix='$'
                        suffix={values.lease.currency}
                      />
                      <FormError name='lease.monthlyRent' />
                    </InfoField>
                    <InfoField title='Currency'>
                      <SelectInput
                        options={planCurrencyOptions}
                        placeholder='Select Currency'
                        name='lease.currency'
                        value={planCurrencyOptions.find(
                          (status) => status.value === values.lease.currency
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'lease.currency',
                            selectedOption?.value || ''
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                  </InfoGrid>
                  <InfoGrid>
                    <InfoField title='Upload New Lease' className='col-span-2'>
                      <FileUploadInput
                        disableUpload={disableFileUpload}
                        isUploadError={uploadStatus.isError}
                        isUploadLoading={uploadStatus.isLoading}
                        isUploadSuccess={
                          uploadStatus.isSuccess ||
                          !isEmpty(planData?.lease?.document)
                        }
                        handlePDFClear={() => {
                          setFieldValue('lease.document', '');
                        }}
                        handlePDFUpload={async (file) => {
                          let uploadUrlReq;
                          setUploadStatus({
                            isSuccess: false,
                            isLoading: true,
                            isError: false,
                          });

                          // Get the signing url for uploading to s3
                          try {
                            uploadUrlReq = await uploadRequestUrl({
                              fileName: file.name,
                              contentType: file.type,
                            }).unwrap();
                          } catch (e) {
                            setUploadStatus({
                              isSuccess: false,
                              isError: true,
                              isLoading: false,
                            });
                            toast.error(
                              e?.msg ||
                                e?.message ||
                                'failed to upload document'
                            );
                            return;
                          }

                          if (
                            !uploadUrlReq?.data?.uploadUrl ||
                            !uploadUrlReq?.data?.viewUrl
                          ) {
                            setUploadStatus({
                              isSuccess: false,
                              isError: true,
                              isLoading: false,
                            });
                            toast.error('failed to upload document');
                          }

                          // Upload the file to s3

                          try {
                            await uploadFileToS3({
                              s3Url: uploadUrlReq?.data?.uploadUrl,
                              file,
                            });

                            // Have to set the uploaded file url on the lease document
                            setUploadStatus({
                              isSuccess: true,
                              isError: false,
                              isLoading: false,
                            });
                            setDisableFileUpload(true);
                            setFieldValue(
                              'lease.document',
                              uploadUrlReq?.data?.viewUrl
                            );
                            toast.info(
                              uploadUrlReq?.status ||
                                'file has been uploaded successfully'
                            );
                          } catch (e) {
                            setUploadStatus({
                              isSuccess: false,
                              isError: true,
                              isLoading: false,
                            });
                            toast.error(
                              e?.msg ||
                                e?.message ||
                                'failed to upload document'
                            );
                            return;
                          }
                        }}
                      />
                      <FormError name='lease.document' />
                    </InfoField>
                    <InfoField title='Uploaded Lease' className='col-span-2'>
                      <DocumentPreview
                        label={planData?.lease?.document}
                        url={planData?.lease?.document}
                      />
                    </InfoField>
                    <InfoField title='Lease Verification'>
                      <SelectInput
                        options={documentStatusOptions}
                        placeholder='Select Status'
                        name='leaseVerification'
                        value={documentStatusOptions.find(
                          (status) => status.value === values.leaseVerification
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'leaseVerification',
                            selectedOption?.value || ''
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </InfoField>
                  </InfoGrid>
                </InfoCard>
              </Form>
            );
          }}
        </Formik>
      </div>
    </DashboardWrapperLayout>
  );
};

export default PlanDetails;
