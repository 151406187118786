import { useState } from 'react';
import ErrorData from 'components/shared/error-data';
import { toast } from 'react-toastify';
import LoadingSpinner from 'components/shared/loading-spinner';
import DeleteModal from 'components/shared/delete-modal';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { Link, useParams } from 'react-router-dom';
import PlansTable from 'components/plans/plans-table';
import Button from 'common/components/button';
import EmptyData from 'components/shared/empty-data';
import StatusLabel from 'components/owners/status-label';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { isEmpty } from 'utils/isEmpty';
import {
  useGetOwnerQuery,
  useDeleteOwnerMutation,
  useFindPropertyQuery,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import Toggle from 'components/shared/toggle';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import { formatPhoneNumber } from 'react-phone-number-input';
import PropertiesTable from 'components/properties/properties-table';

const View = () => {
  const navigate = useNavigate();
  const { ownerId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const {
    data: ownerData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
    isFetching: isGetFetching,
  } = useGetOwnerQuery(ownerId);
  const [
    deleteOwner,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
    // { isLoading: updatePlanLoading },
  ] = useDeleteOwnerMutation();

  // Find properties of owner
  const {
    data: propertiesQuery,
    isError: isGetPropertyError,
    error: getPropertyError,
    isLoading: findPropertiesLoading,
  } = useFindPropertyQuery({
    limit: 200,
    owner: ownerId,
  });

  console.log(propertiesQuery);

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  if (!ownerData) return <LoadingSpinner />;
  return (
    <DashboardWrapperLayout title={ownerData?.name || 'Company Details'}>
      {/* Loading Animation */}
      {(isUpdateLoading ||
        isUpdateFetching ||
        isGetFetching ||
        isGetLoading) && <LoadingSpinner />}

      {/* Delete function */}
      {deleteModalOpen && (
        <DeleteModal
          handleDelete={() => {
            deleteOwner({ id: ownerId })
              .unwrap()
              .then(() => {
                navigate('/dashboard/owners');
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModalOpen(false);
          }}
        />
      )}

      <div className='flex flex-col w-full gap-6'>
        {/* Combined breadcrumb + edit toggle header */}
        <div className='w-full flex gap-3 justify-between'>
          {/* breadcrumb */}
          <div className='flex gap-3 items-center'>
            <Link
              to='/dashboard/home'
              className='font-normal text-base text-[#CED0CE]'
            >
              Dashboard
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            <Link
              to='/dashboard/owners'
              className='text-[#CED0CE] text-base font-normal'
            >
              Companies{' '}
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            <Link
              to={`/dashboard/owners/${ownerData?.id}`}
              className='text-[#CED0CE] text-base font-normal'
            >
              {ownerData?.id.toUpperCase()}
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            <Link
              to={`/dashboard/properties/${ownerData?.id}`}
              className='text-[#191923] text-base font-normal'
            >
              View{' '}
            </Link>
          </div>

          <Toggle
            selected={'view'}
            editPath={`/dashboard/owners/${ownerData?.id}/edit`}
            viewPath={`/dashboard/owners/${ownerData?.id}`}
          />
        </div>

        {/* Action buttons */}
        <div className='w-full flex gap-2 justify-end items-center'>
          <Button
            title='Add Property'
            wFull={false}
            className='px-6'
            onClick={() =>
              navigate(`/dashboard/properties/new?owner=${ownerData?.id}`)
            }
          />
          <Button
            onClick={() => {
              setDeleteModalOpen(true);
            }}
            Icon={AiOutlineDelete}
            theme='secondary'
            title='Delete Company'
            wFull={false}
            className='px-6'
          />
        </div>

        {/* Owner Details Table */}
        <InfoCard title='Company Information'>
          <InfoGrid>
            <InfoField title='Name'>{ownerData?.name}</InfoField>
            <InfoField title='ID'>{ownerData?.id}</InfoField>
            <InfoField title='Type'>
              <StatusLabel status={ownerData?.kind} />
            </InfoField>
            <InfoField title='Status'>
              <StatusLabel status={ownerData?.status} />
            </InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Email'>{ownerData?.email}</InfoField>
            <InfoField title='Phone'>
              {formatPhoneNumber(ownerData?.phone)}
            </InfoField>
            <InfoField title='Website'>{ownerData?.website}</InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Rev Share Rate'>
              {`${
                ownerData?.revShareRate
                  ? (ownerData?.revShareRate * 100).toFixed(2)
                  : '0'
              }%`}
            </InfoField>
            <InfoField title='Flat Commission'>
              {`$${
                ownerData?.flatCommission
                  ? (ownerData?.flatCommission).toFixed(2)
                  : '0.00'
              } Per Plan`}
            </InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Notes' className='col-span-4'>
              {ownerData?.notes}
            </InfoField>
          </InfoGrid>
        </InfoCard>

        {/* Owner's Properties Table */}
        <InfoCard
          title={`${
            ownerData?.kind == 'Owner' ? 'Owned' : 'Managed'
          } Properties`}
        >
          {isEmpty(propertiesQuery?.data) ? (
            <EmptyData />
          ) : (
            <PropertiesTable
              isRounded={false}
              properties={propertiesQuery?.data}
            />
          )}
        </InfoCard>
      </div>
    </DashboardWrapperLayout>
  );
};

export default View;
